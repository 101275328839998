import React from 'react'
import { Link } from 'gatsby';

const randomImage = (project) => {
  if (!project) return '';

  const images = project.images.length > 0 ? project.images
    : project.sub.map(s => s.images).reduce((xs, ys) => [...xs, ...ys]);

  const image = images.sort(() => 0.5 - Math.random()).slice(0, 1)[0];

  return image;

}

const Projects = ({ path, images }) => {
  return <div className="row"> {
    images.sub.map(project => <div className="col-md-6 col-sm-6">
      <div className="canvas-box mb25 project-pic">
        <span className="text-center">
          <Link to={project.url}>
            <i className="fa">
              <img src={randomImage(project).thumbnail} alt='' />
            </i>
            <h4 className="color-style2">
              {project.menu}
            </h4>
          </Link>
        </span>
        <p> {project.text}</p>
      </div>
    </div>
    )
  }
  </div>
}

export default Projects;