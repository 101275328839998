import React from "react"
import { Link } from "gatsby"
import { Router, Redirect } from '@reach/router'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Pictures from '../../components/katalog/Pictures';
import Images from '../../components/katalog/images/images.json';
import Projects from '../../components/katalog/Projects';

const ProjectsIndex = ({ pageContext }) => {
  return <Layout pageContext={pageContext}>
    <SEO title="Реализованные проекты" />


    <section id="home" className="hero hero_full_screen hero_parallax  text-center  _parallax-window" data-stellar-background-ratio="0.5">
      <div className="hero_parallax_inner">
        <div className="container aisle-work">

          {/* <Router>
            <Pictures path='/projects/:category/:project/:place' />
            <Pictures path='/projects/:category/:project' /> */}
            {/* <Pictures path='/projects/:category/' /> */}
     
            <Projects path='/projects/' images={Images} /> 
          {/* </Router> */}
        </div>
      </div>
    </section>
  </Layout>
}

export default ProjectsIndex
